import * as React from "react"
/** @jsx jsx */
import { jsx, Link as TLink, Box, Grid, Text, Heading } from "theme-ui"
import { Link } from "gatsby"

const CardListItem = ({ posts }) => (
  <Grid gap={2} columns={["auto", "1fr 1fr"]} mb={5}>
    {posts.map((post, index) => {
      const title = post.frontmatter.title || post.fields.slug

      if (index == 0) {
        return
      }

      return (
        <Box
          variant="cards.outline"
          sx={{
            borderRadius: [8],
            paddingTop: `1rem`,
            paddingBottom: `1rem`,
            paddingLeft: `2rem`,
            paddingRight: `2rem`,
            flex: "1 1 auto",
          }}
        >
          <article
            className="post-list-item"
            itemScope
            itemType="http://schema.org/Article"
          >
            <header sx={{ marginBottom: `1rem` }}>
              <Heading as="h2" mb={3} variant="cards.h2">
                <TLink
                  as={Link}
                  to={post.fields.slug}
                  itemProp="url"
                  variant="links.secondary"
                >
                  <span itemProp="headline">{title}</span>
                </TLink>
              </Heading>
              <small>{post.frontmatter.date}</small>
            </header>
            <section>
              <TLink
                as={Link}
                to={post.fields.slug}
                itemProp="url"
                variant="links.text"
              >
                <Text variant="cards.p">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </Text>
              </TLink>
            </section>
          </article>
        </Box>
      )
    })}
  </Grid>
)

export default CardListItem
