/** @jsx jsx */
import * as React from "react"
import { jsx, Box } from "theme-ui"

const Title = ({ text, children, as = `h2`, className = `` }) => (
  <div
    sx={{
      justifyContent: `space-between`,
      alignItems: `center`,
      borderBottomStyle: `solid`,
      borderBottomWidth: `0.5px`,
      borderBottomColor: `divider`,
      pb: 3,
      mb: 4,
      flexFlow: `wrap`,
      boxSizing: `border-box`,
      display: `flex`,
    }}
  >
    <Box as={as} variant="text.heading" className={className}>
      {text}
    </Box>
    <div
      sx={{
        color: `secondary`,
        a: {
          variant: `links.secondary`,
        },
      }}
    >
      {children}
    </div>
  </div>
)

export default Title
