/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import { graphql } from "gatsby"
import TagLine from "../components/tagLine"
import HeroCard from "../components/heroCard"
import Title from "../components/title"
import BlogListItem from "../components/blogListItem"
import CardListItem from "../components/cardListItem"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Box } from "theme-ui"
import Parser from "html-react-parser"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes
  const author = data.site.siteMetadata?.author

  if (posts.length === 0) {
    return (
      <Layout location={location} wrapper="global-wrapper" title={siteTitle}>
        <Seo title="All posts" />
        <TagLine text={siteTitle} />
        <HeroCard />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle} wrapper="global-wrapper">
      <Seo title="All posts" />
      <TagLine text={Parser(siteTitle)} />
      <HeroCard post={posts[0]} />
      <CardListItem posts={posts} />
      <Title text="All Posts">Read all posts</Title>
      <Box variant="wrapper.home">
        {posts.map(post => {
          return <BlogListItem post={post} />
        })}
      </Box>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featuredImage {
            relativePath
            childImageSharp {
              fluid(base64Width: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
