/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Text, Heading, Paragraph } from "theme-ui"

const TagLine = ({ text }) => {
  const data = useStaticQuery(graphql`
    query TagLineQuery {
      site {
        siteMetadata {
          title
          author {
            summary
          }
        }
      }
    }
  `)

  const title = data.site.siteMetadata?.title
  const summary = data.site.siteMetadata?.author?.summary

  return (
    <Box sx={{ marginTop: `3rem`, marginBottom: `4rem` }}>
      <Heading
        as="h2"
        variant="styles.h2"
        sx={{
          letterSpacing: `1.1px`,
          fontWeight: `900`,
        }}
      >
        {title}
      </Heading>
      <Text as={Paragraph} variant="p">
        {summary}
      </Text>
    </Box>
  )
}

export default TagLine
