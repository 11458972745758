import * as React from "react"
import {
  Card,
  Box,
  Heading,
  Text,
  Paragraph,
  jsx,
  Link as TLink,
} from "theme-ui"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Img from "gatsby-image"

const HeroCard = ({ post }) => {
  let title = post.frontmatter.title
  let description = post.frontmatter.description || post.excerpt
  let slug = post.fields.slug
  let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid
  return (
    <Card
      variant="hero.card"
      sx={{
        position: "relative",
        gridTemplateColumns: ["auto", "3fr 2fr"],
        display: "grid",
        backgroundImage: [null, `url(${featuredImgFluid.base64})`],
        backgroundSize: "cover",
        mt: `3rem`,
      }}
    >
      <Box sx={{ width: ["100%", "0%"] }}>
        <Img height={[null, 500]} fluid={featuredImgFluid} />
      </Box>
      <Box
        variant="cards.rightRoundOutline"
        sx={{
          paddingTop: `1rem`,
          paddingBottom: `1rem`,
          paddingLeft: `2rem`,
          paddingRight: `2rem`,
        }}
      >
        <article
          className="post-list-item"
          itemScope
          itemType="http://schema.org/Article"
        >
          <Heading as="h1" mb={4} variant="hero.h1">
            <TLink as={Link} to={slug} itemProp="url" variant="links.secondary">
              {title}
            </TLink>
          </Heading>
          <section>
            <Text mb={3} variant="hero.p">
              <TLink as={Link} to={slug} itemProp="url" variant="links.text">
                {description}
              </TLink>
            </Text>
          </section>
        </article>
      </Box>
    </Card>
  )
}

export default HeroCard
